var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"851957786273cf29b7bff67161f23ce40afba95e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import pkg from "../../package.json";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const BRANCH_NAME = process.env.VERCEL_GIT_COMMIT_REF;

Sentry.init({
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ["veylinx.com"],
    }),
  ],
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  release: pkg.version,
  enabled: ["main", "staging"].includes(BRANCH_NAME),
});
